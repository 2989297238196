import React from "react";
import ReactAudioPlayer from "react-audio-player";
import {
   Text, TextContainer, OuterTextContainer
} from "./styledcomponents";

export const playerContent = {
    JanineWestphal: {
        text: "„Wir bleiben in Verantwortung, diesen Satz habe haben mein Team und ich so sehr verinnerlicht. Und das ist mein Lichtblick, denn ich kann etwas ausrichten. In welcher Phase spüren wir eine größere Selbstwirksamkeit als jetzt?“",
        text2: "Janine Westphal, Leitungsteam KiTa-Vogelnest - Essen-Altenessen, 5. Mai 2020",
        audio: "/audio/02_JanineWestphal_webfinal.m4a"
    },
    BaerbelWagner: {
        text: "„Egal wie sehr wir vor Corona daran gearbeitet haben, eine gute Work-Life-Balance hinzubekommen und wie sehr wir uns geschworen haben, dass wir aufeinander aufpassen und auch abschalten können: Nun habe ich den Eindruck, dass die Kita mit uns körperlich verschweißt ist.“",
        text2: "Bärbel Wagner, Leitung KiTa Vogelnest - Essen-Altenessen, 5. Mai 20220",
        audio: "/audio/03_BaerbelWagner_webfinal.m4a"
    },
    BischofOverbeck: {
        text: "„Distanz passt nicht zum Christsein. Wenngleich Abstand zum anderen in diesen Tagen Nächstenliebe offenbart.“",
        text2: "Bischof Franz-Josef Overbeck",
        audio: "/audio/05_BischofOverbeck_webfinal.m4a"
    },
    FamiliePaschy: {
        text: "„Die Geburt in der Corona-Zeit ist eine Geschichte des Wartens.“",
        text2: "Familie Paschy",
        audio: "/audio/07_FamiliePaschy_webfinal.m4a"
    },
    BeateJanas: {
        text: "Separates Mittagessen für die streng getrennten Bewohner eines Stockwerkes des AWO-Essen Altenpflegeheims Kurt-Schumacher-Zentrum. Vor Corona haben an jedem der Tische jeweils vier Bewohner gegessen. Seit dem 14.03.2020 gilt ein Besuchsverbot in Alten und Pflegeheimen in NRW. Bei einem Erstverstoß kann ein Bußgeld in Höhe von 200 Euro verhängt werden.",
        text2: "Beate Janas, Pflegebereichsleitung - Essen, 23. April 2020",
        audio: "/audio/09_Janas_webfinal.m4a"
    },
    WalburgaHinz: {
        text: "„Ich bin alt. Schlimm ist das doch für junge Menschen.“",
        text2: "Walburga Hinz, Altenheim Bewohnerin - Essen, 23. April 2020",
        audio: "/audio/08_Hinz_webfinal.m4a"
    },
    YvonneWittig: {
        text: "„Corona ändert nichts an dem Spaß, den wir miteinander haben.“",
        text2: "Yvonne Wittig, Alltagsbegleiterin - Essen, 23. April 2020",
        audio: "/audio/10_Wittig_webfinal.m4a"
    },
    JuergenKrause: {
        text: "„Corona offenbart Zusammenhalt. Das ist der Solidaritätspakt meiner Kunden mit mir.“",
        text2: "Jürgen Krause",
        audio: "/audio/12_JuergenKrause_webfinal.m4a"
    },
    LauraZeiger: {
        text: "„Musik bedeutet gerade jetzt extrem viel.“",
        text2: "Laura Zeiger, Sopranistin",
        audio: "/audio/14_LauraZeiger_webfinal.m4a"
    },
    LeoTeichmann: {
        text: "Die Freunde Leo und Manuel können nur noch Joggen als Sport betreiben. Nach dem Sport baden die beiden in der Ruhr. Die Schwimmbäder haben seit dem 17. März 2020 wegen Corona geschlossen.",
        text2: "Leo Teichmann, Student - Essen, 24. März 2020",
        audio: "/audio/15_LeoTeichmann_webfinal.m4a"
    },
    MaiclPlatzek: {
        text: "„Im Lockdown umdenken. Soziale Arbeit ist in vielen Bereichen möglich.“",
        text2: "Maicl Platzek, Diplom-Pädagoge",
        audio: "/audio/17_Platzek_webfinal.m4a"
    },
    AnnelieHegemann: {
        text: "„Abi-Zeugnisvergabe mit Hygienekonzept: Aber die Gemeinschaft bleibt trotzdem spürbar.“",
        text2: "Annelie Hegemann, Mathelehrerin",
        audio: "/audio/19_AnnelieHegemann_webfinal.m4a"
    },
    NataschaL: {
        text: "„Komm klar und richte dein Krönchen!“",
        text2: "Natascha L. - Friseurin",
        audio: "/audio/21_Natascha_L_webfinal.m4a"
    },
    RamonaLettkamp: {
        text: "„Für alles gibt es nur noch den einen Ort: die Wohnung.“",
        text2: "Ramona Lettkamp, alleinerziehende Mutter",
        audio: "/audio/23_RamaonaLettkamp_webfinal.m4a"
    },
    ManuelGalemann: {
        text: "„Man muss schon aufpassen, dass man nicht den Willen verliert, dran zu bleiben.“",
        text2: "Manuel Galemann, Musikstudent",
        audio: "/audio/Website_Trompeter_Manuel_Galemann.m4a"
    },
    ObaydaSyrischeFamilie: {
        text: "„Corona ist ein schlechter Traum.“",
        text2: "Obayda Sabbagh mit Tochter und Sohn",
        audio: "/audio/28_Obayda_Syrische_Familie_webfinal.m4a"
    },
    MichaelWalta: {
        text: "„Beim Zaubern sind alle gleich.“",
        text2: "Michael Walta, Bauchredner und Zauberer",
        audio: "/audio/Website_Bauchredner_Michael_Walta.m4a"
    },
    JessicaSoyk: {
        text: "„Muss erst eine Pandemie kommen, damit Menschen verstehen, was Pflegekräfte leisten?“",
        text2: "Jessica Soyk, Fachkrankenschwester für Intensivmedizin",
        audio: "/audio/32_JessicaSoyk_webfinal.m4a"
    },
    KenanDemir: {
        text: "„Am Ende sind es über 100 Tage, die ich in den Kliniken verbringe.“",
        text2: "Kenan Demir, Corona infizierter Intensivpatient",
        audio: "/audio/34_KenanDemir_webfinal.m4a"
    },
    HochzeitspaarSchalla: {
        text: "„Die Belohnung: Eine Hochzeit, die wir nie vergessen werden.“",
        text2: "Anna und Marcel Schalla, Hochzeitspaar",
        audio: "/audio/36_Schalla_webfinal.m4a"
    },
    KlausSiebald: {
        text: "„Wir hingen fest in Kapstadt.“",
        text2: "Klaus Siebald",
        audio: "/audio/38_KlausSiebald_webfinal.m4a"
    },
    ThorstenTeriete: {
        text: "„Die Hälfte der Zeit lief normal! Das hat uns den Arsch gerettet.“",
        text2: "Thorsten Teriete, Kneipenwirt",
        audio: "/audio/40_ThorstenTeriete_webfinal.m4a"
    },
    RamonaKamminski: {
        text: "„Das Glas ist halb voll, nicht halb leer!“",
        text2: "Ramona Kamminski, alleinerziehende Mutter",
        audio: "/audio/42_RamonaKamminski_webfinal.m4a"
    },
    RobinLelgemann: {
        text: "„Das Dilemma mit den wechselnden Bestattungsregeln dem Trauernden rational vermitteln.“",
        text2: "Robin Lelgemann, Bestatter - Essen, 11. August 2020",
        audio: "/audio/43_RobinLelgemann_webfinal.m4a"
    },
    IljaD: {
        text: "Trauerfeier für Natalia D. mit anschließender Beisetzung. Ihr Ehemann Alexander und Sohn Ilja trauern am Sarg, es ist unter den aktuellen Coronabedingungen nur im Freien möglich. Viele Verwandte der russischstämmige Familie konnten wegen den aktuellen Reisebeschränkungen nicht an der Bestattung teilnehmen. Alle anwesenden Trauernden müssen sich auf eine Liste eintragen, sollten eine Maske tragen und die Abstände untereinander einhalten.",
        text2: "Ilja D. - Essen, 11. August 2020",
        audio: "/audio/44_Ilja_D_webfinal.m4a"
    },
    KavehShoaei: {
        text: "„Zu helfen hilft, sich lebendiger zu fühlen.“",
        text2: "Kaveh Shoaei, Flüchtlingsberater von ProAsyl Essen",
        audio: "/audio/46_Shoaei_webfinal.m4a"
    }
}

export class AudioPlayer extends React.PureComponent {

    render () {

        const {mobile, audioId} = this.props;

        const containerStyle = mobile ? {paddingTop: 70} : {};
        const playerStyle = mobile ? {outline:"none", width:"100%"} : {outline:"none"};

        return (
            <OuterTextContainer right style={containerStyle}>
                <TextContainer white>
                    <Text tall black>{playerContent[audioId].text}</Text>
                    <Text bottompadding underlined>
                        <ReactAudioPlayer
                            src={window.location.origin + playerContent[audioId].audio}
                            style={playerStyle}
                            controls
                            controlsList="nodownload"
                        />
                    </Text>
                    <Text bottompadding black>{playerContent[audioId].text2}</Text>
                    {mobile && <Text bottompadding black style={{justifyContent: "center", display: "flex", margin: 0, padding: 0, marginTop: 50}}><a href={window.location.origin}>Alle Bilder und Stories >></a><br/><br/><br/><br/><br/><br/><br/></Text>}
                </TextContainer>
            </OuterTextContainer>
        );
    }

}