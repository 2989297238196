import React from "react";
import styled, {css} from "styled-components";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";
import presse01 from '../img/presse01.png';
import presse02 from '../img/presse02.png';
import presse03 from '../img/presse03.png';

const ImprintContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-Y: scroll;
  background-color: #fff !important;
  color: #333 !important;
  paddingTop: 100;
  paddingBottom: 100;
`;

export class About extends React.Component {

  render() {
    return (
      <ImprintContainer>
        <OuterTextContainer>
          <TextOnlyContainer>
            <Text black xl underlined topmargin>Gruppenausstellung im Kunstmuseum Bochum 17. April 2021</Text>
            <Text black bottompadding>
            <iframe
              style={{marginTop: 50}}
              width="560" height="315" src="https://www.youtube-nocookie.com/embed/HeEcRulntXo"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            </Text>

            <Text black xl underlined>Der Fotograf</Text>
            <Text black bottompadding>
              <b>Andreas Teichmann</b>, geb. 1970, lebt mit seiner Familie in Essen und arbeitet seit 28 Jahren als freier Fotograf. Er hat an der Folkwang-Universität der Künste studiert. Ein Schwerpunkt seiner Arbeit ist das Verhältnis des Menschen zu seiner Umwelt.
              <br/><br/>Er hat über die letzten drei Jahrzehnte zahlreiche Publikationen und Auszeichnungen im In- und Ausland bekommen. Darunter die Teilnahme an der World Press Masterclass und dem Hasselblad Master. Er ist seit 20 Jahren Mitglied der Agentur laif Photos & Reportagen, freelens und der DGPh.
              <br/><br/><a href={"http://www.andreasteichmann.de/"} target={"_blank"}>www.andreasteichmann.de</a>
              <br/><a href={"https://www.instagram.com/andreas.teichmann/"} target={"_blank"}>Instagram: andreas.teichmann</a>
            </Text>

            <Text black xl underlined>Auszeichnungen</Text>
            <Text black bottompadding>
              <b>Pixelprojekt_Ruhrgebiet</b>
              <br/>Neuaufnahmen 2020/2021:
              <br/><a href={"https://www.pixelprojekt-ruhrgebiet.de/serie/der-mensch-bleibt-ein-soziales-wesen"} target={"_blank"}>www.pixelprojekt-ruhrgebiet.de/serie/der-mensch-bleibt-ein-soziales-wesen</a>
              <br/><br/>
              <br/><b>Rückblende 2020</b>
              <br/>Der deutsche Preis für politische Fotografie
              <br/>Shortlist 2020 Serie:
              <br/><a href={"https://rueckblende.rlp.de/entry/2020-teichmann-andreas-41006/"} target={"_blank"}>rueckblende.rlp.de/entry/2020-teichmann-andreas-41006/</a>
              <br/><br/>
              <br/><b>Vonovia Award für Fotografie 2020</b>
              <br/>Shortlist 2020 Beste Fotoserie:
              <br/><a href={"https://award.vonovia.de/de-de/wettbewerb-2020/shortlist-2020/profi/andreas-teichmann"} target={"_blank"}>award.vonovia.de/de-de/wettbewerb-2020/shortlist-2020/profi/andreas-teichmann</a>
            </Text>

            <Text black xl underlined>Presse</Text>
            <Text black bottompadding>
              <b>GEO Wissen Gesundheit Ausgabe 16/2021</b>
              <br/><br/><img src={presse01} style={{maxWidth: "100%"}} />
            </Text>
            <Text black bottompadding>
              <b>Akademie Akzente 02/2021</b>
              <br/><a href={"https://www.die-wolfsburg.de/fileadmin/akzente/akzente54.pdf/"} target={"_blank"}>www.die-wolfsburg.de/fileadmin/akzente/akzente54.pdf</a>
            </Text>
            <Text black bottompadding>
              <b>Uniklinikum Essen „Wie Is“  21.05.2021</b>
              <br/><a href={"https://www.wie-is.ume.de/2021-01/fotoprojekt/"} target={"_blank"}>www.wie-is.ume.de/2021-01/fotoprojekt/</a>
            </Text>
            <Text black bottompadding>
              <b>digit - 28.05.2021</b>
              <br/><br/><img src={presse02} style={{maxWidth: "100%"}} />
            </Text>
            <Text black bottompadding>
              <b>AWO-Essen Online - 08.02.2021</b>
              <br/><a href={"https://www.awo-essen.de/ueber-uns/aktuelles/d/foto-serie-mit-der-awo-erregt-grosse-aufmerksamkeit"} target={"_blank"}>www.awo-essen.de/ueber-uns/aktuelles/d/foto-serie-mit-der-awo-erregt-grosse-aufmerksamkeit</a>
            </Text>
            <Text black bottompadding>
              <b>WAZ am Wochenende - 21.11.2020</b>
              <br/><br/><img src={presse03} style={{maxWidth: "100%"}} />
            </Text>
            <Text black bottompadding>
              <b>freelens - 06.11.2020, Interview mit Andreas Teichmann</b>
              <br/><a href={"https://freelens.com/fotostrecken/mir-hat-es-mut-gemacht/"} target={"_blank"}>freelens.com/fotostrecken/mir-hat-es-mut-gemacht/</a>
            </Text>
            <Text black bottompadding>
            <b>taz - 27.10.2020, Interview mit Andreas Teichmann</b>
            <br/><a href={"https://taz.de/Andreas-Teichmann-ueber-Bilder-zu-Corona/!5724251/"} target={"_blank"}>taz.de/Andreas-Teichmann-ueber-Bilder-zu-Corona/!5724251/</a>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </Text>

          </TextOnlyContainer>
        </OuterTextContainer>
      </ImprintContainer>
    );
  }
}