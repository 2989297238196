import React from "react";
import styled, {css} from "styled-components";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";
import logo1 from "../img/logo_bib.png";
import logo2 from "../img/logo_kulturwerk.png";
import logo3 from "../img/logo_wolfsburg.png";

const ImprintContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-Y: scroll;
  background-color: #fff !important;
  color: #333 !important;
  paddingTop: 100;
  paddingBottom: 100;
`;

export class Imprint extends React.Component {


  render() {
    return (
      <ImprintContainer>
        <OuterTextContainer>
          <TextOnlyContainer>
            <Text black xl underlined>Impressum</Text>
            <Text black>
              <b>Herausgeber, Projektkoordination und Fotografie</b>
                <br/>Andreas Teichmann
                <br/>Dipl.-Designer
                <br/>Holteyer Str.2
                <br/>D-45289 Essen
                <br/>Tel: +49 171 544 9220
                <br/>info@andreasteichmann.de
                <br/>Webseite: www.andreasteichmann.de
                <br/>Umsatzsteuernummer (§27a UStG): DE 196 794 196
              <p>
                <b>Text, Interview und Audio</b>
                <br/>Kerstin Wördehoff
                <br/>kerstin.woerdehoff@yahoo.de
                <br/>Liliane Zuuring
                <br/>l.zuuring@lichtbildhauer.de
              </p>
              <p>
                <b>Website, Design und Programmierung</b><br/>
                Adam Dreessen / Dreessen IT-Consulting GmbH<br/>
                ad@dreessen-it.com
              </p>
              <p>
                <b>Dank an</b>
                <br/>Bistum Essen
                <br/>AWO Essen
                <br/>Oliver Kern
                <br/>Uniklinikum Essen
                <br/>Adam Dreessen
                <br/>ProAsyl Essen
                <br/>Bärbel Wagner
                <br/>Achim Gerhardt
                <br/>GEÜ
                <br/>Bernd Müllender
                <br/>Familie Lelgemann
                <br/>Peter Bitzer
                <br/>laif - Photos & Reportagen
                <br/>Marcel Bagus
              </p>
              <p>
                <b>Förderer und Partner</b>
                <br/><a href={"https://www.bibessen.de/homepage.html"} target={"_blank"}><img src={logo1} width={250} style={{marginTop: 20}} /></a>
                <br/><img src={logo2} width={250} style={{marginTop: 20}} />
                <br/><a href={"https://www.die-wolfsburg.de/"} target={"_blank"}><img src={logo3} width={250} style={{marginTop: 20}} /></a>
              </p>
            </Text>
            <Text black xl underlined>Datenschutzerklärung</Text>
            <Text black>
              <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
              <p>Andreas Teichmann
                <br/>Dipl.-Designer
                <br/>Holteyer Str.2
                <br/>D-45289 Essen
                <br/>Tel: +49 201 578839
                <br/>info@andreasteichmann.de
              </p>
              <h2>Ihre Betroffenenrechte</h2>
              <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
              <ul>
                <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
                <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
              </ul>
              <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
              <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
              <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
              <p></p><h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
              <h3>Art und Zweck der Verarbeitung:</h3>
              <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
              <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
              <ul>
                <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>zur Optimierung unserer Website.</li>
              </ul>
              <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren. </p>
              <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
              <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.</p>
              <h3>Empfänger:</h3>
              <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.</p>
              <p></p><h3>Speicherdauer:</h3>
              <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist. </p>
              <p> Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine  Zuordnung  des aufrufenden Clients nicht mehr möglich ist.</p>
              <p></p><h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
              <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen. </p>
              <p></p><h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
              <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</p>
              <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" rel="noopener nofollow" target="_blank">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/" rel="noopener nofollow" target="_blank">https://www.google.com/policies/privacy/</a>.</p>
              <p></p><h2>Eingebettete YouTube-Videos</h2>
              <p>Auf unserer Website betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist einer Tochtergesellschaft der Google LLC, 1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA (nachfolgend „Google“). Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.
              Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.
              Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre (https://policies.google.com/privacy).
              </p>
              <p></p><hr/>
              <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
              <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
              <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
              <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
              <h3>Empfänger eines Widerspruchs</h3>
              <p>Andreas Teichmann
                <br/>Holteyer Str.2
                <br/>D-45289 Essen
                <br/>Tel: +49 201 578839
                <br/>info@andreasteichmann.de
              </p>
              <hr/>
              <h2>Änderung unserer Datenschutzbestimmungen</h2>
              <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
              <h2>Fragen an den Datenschutzbeauftragten</h2>
              <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
              <p>Andreas Teichmann
                <br/>Holteyer Str.2
                <br/>D-45289 Essen
                <br/>Tel: +49 201 578839
                <br/>info@andreasteichmann.de</p>
              <p><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener">externe Datenschutzbeauftragte</a> (Version #2020-09-30).</em></p>
            </Text>
          </TextOnlyContainer>
        </OuterTextContainer>
      </ImprintContainer>
    );
  }
}