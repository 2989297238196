import React from "react";
import ReactAudioPlayer from 'react-audio-player';
import {OuterTextContainer, TextContainer, TextOnlyContainer, Text} from "../components/styledcomponents";

import image01 from "../img/01_BaerbelWagner_webfinal.jpg";
import image01a from "../img/01_IPhone_BaerbelWagner_webfinal.jpg";
import image02 from "../img/02_JanineWestphal_webfinal.jpg";
import image03 from "../img/03_BaerbelWagner_webfinal.jpg";
import image04 from "../img/04_BischofOverbeck_webfinal.jpg";
import image05 from "../img/05_BischofOverbeck_webfinal.jpg";
import image06 from "../img/06_FamiliePaschy_webfinal.jpg";
import image07 from "../img/07_FamiliePaschy_webfinal.jpg";
import image08 from "../img/08_Hinz_webfinal.jpg";
import image09 from "../img/09_Janas_webfinal.jpg";
import image10 from "../img/10_Wittig_webfinal.jpg";
import image11 from "../img/011_JuergenKrause_webfinal.jpg";
import image12 from "../img/12_JuergenKrause_webfinal.jpg";
import image13 from "../img/13_LauraZeiger_webfinal.jpg";
import image14 from "../img/14_LauraZeiger_webfinal.jpg";
import image15 from "../img/15_LeoTeichmann_webfinal.jpg";
import image16 from "../img/16_Platzek_webfinal.jpg";
import image17 from "../img/17_Platzek_webfinal.jpg";
import image18 from "../img/18_AnnelieHegemann_webfinal.jpg";
import image19 from "../img/19_AnnelieHegemann_webfinal.jpg";
import image20 from "../img/20_Natascha_L_webfinal.jpg";
import image21 from "../img/21_Natascha_L_webfinal.jpg";
import image22 from "../img/22_Natascha_L_webfinal.jpg";
import image23 from "../img/23_RamaonaLettkamp_webfinal.jpg";
import image24 from "../img/24_RamaonaLettkamp_webfinal.jpg";
import image25 from "../img/25_ManuelGalemann_webfinal.jpg";
import image26 from "../img/26_ManuelGalemann_webfinal.jpg";
import image27 from "../img/27_Obayda_Syrische_Familie_webfinal.jpg";
import image28 from "../img/28_Obayda_Syrische_Familie_webfinal.jpg";
import image29 from "../img/29_MichaelWalta_webfinal.jpg";
import image30 from "../img/30_MichaelWalta_webfinal.jpg";
import image31 from "../img/31_JessicaSoyk_webfinal.jpg";
import image32 from "../img/32_JessicaSoyk_webfinal.jpg";
import image33 from "../img/33_KenanDemir_webfinal.jpg";
import image34 from "../img/34_KenanDemir_webfinal.jpg";
import image35 from "../img/35_Schalla_webfinal.jpg";
import image36 from "../img/36_Schalla_webfinal.jpg";
import image37 from "../img/37_KlausSiebald_webfinal.jpg";
import image38 from "../img/38_KlausSiebald_webfinal.jpg";
import image39 from "../img/39_ThorstenTeriete_webfinal.jpg";
import image40 from "../img/40_ThorstenTeriete_webfinal.jpg";
import image41 from "../img/41_RamonaKamminski_webfinal.jpg";
import image42 from "../img/42_RamonaKamminski_webfinal.jpg";
import image43 from "../img/43_RobinLelgemann_webfinal.jpg";
import image44 from "../img/44_Ilja_D_webfinal.jpg";
import image45 from "../img/45_Shoaei_webfinal.jpg";
import image46 from "../img/46_Shoaei_webfinal.jpg";
import titel from "../img/titel.png";

const sectionDefaultHeight = "180vh";

export const content = [
  {
    sectionId: "Start",
    height: sectionDefaultHeight,
    image: window.innerWidth > 1200 ? image01 : image01a,
    imagePosition: "top",
    content:
      <OuterTextContainer left><TextContainer white>
        <img src={titel} width="100%" />
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "TXT01",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text tall black bottompadding>
            Im Jahr 2020 hat sich das Leben der Menschen gravierend verändert. Die Folgen des Coronavirus sind eine globale Erfahrung.
            <br/><br/>Mit Beginn der Ausgangsbeschränkungen begrenzte sich mein soziales Leben auf unser Zuhause, das ich mit meiner Frau und unseren Kindern bewohne. Ich kann mich nicht erinnern, jemals so viel Zeit am Stück zusammen mit meiner Familie an diesem einen Ort verbracht zu haben.
            <br/><br/>Das verändert die Wahrnehmung.
            <br/><br/>In den ersten Tagen war ich in einer Art persönlicher Schockstarre und musste mich als Fotograf erst neu orientieren. Bilder von leeren Innenstädten und Toilettenpapier-Regalen waren für mich nur objektive Symptome der aktuellen Lage.
            <br/><br/>Mich interessierte, wie sich andere Menschen im Lockdown mit dieser neuen Lebenswirklichkeit verhalten und ob es sie verändert.
          </Text>
          <Text xl black>Bleibt der Mensch ein soziales Wesen?</Text>
          <Text tall black>
            Diese Frage fotografisch zu untersuchen, war organisatorisch aufwändig und ich benötigte viel Zeit für Vorgespräche sowie eine umfangreiche Schutzausrüstung, um mich und die Menschen, die ich aufsuchte, zu schützen. Erst waren es private Kontakte, die mir Zugang gewährten, dann bin ich über Kirchen, soziale Verbände, Schulen, Kitas, Altenheime und Kliniken mit Menschen zusammen gekommen, für die Social Distancing zu unterschiedlichen „neuen Normalitäten“ geführt hat.
            <br/><br/>Der Begriff „Social Distancing“ ist irreführend. Es müsste „Physical Distancing“ heißen - sozialer Austausch findet nun alternativ im Netz und in Social Media statt.
            <br/><br/>Ich habe beobachtet, dass digitale Kommunikation in dieser Situation eine gutes, helfendes Mittel ist, die Erfahrung von körperlicher Nähe ersetzt sie aber niemals.
            <br/><br/>Es sind kurze Momente, die die emotionale Bandbreite von menschlichen Beziehungen skizzieren.
            <br/><br/><i>Andreas Teichmann, Fotograf, 2020</i>
            <br/><br/>Eine fremde Person via Videokonferenz in sein Innerstes blicken zu lassen, Ängste, Trauer, Freude, Wut, Unsicherheit zu offenbaren in einer Situation, die Menschen und Gesellschaften auf der ganzen Welt zu überfordern scheint: Das erfordert Mut. Den beweisen die Portraitierten. Und sie richten den Blick nicht nur auf die Vergangenheit und die Gegenwart, sondern blicken nach vorn. Erkennen in der Ausnahmesituation auch Momente der Nähe, die in ihnen Dankbarkeit hervorruft und sie stärkt für die Zukunft. Damit senden sie Hoffnung in die Welt.
            <br/><br/>Ihre Geschichten sind Schlaglichter. Beispiele für Geschichten und Gefühle, die Corona in jedes Menschenleben schreibt – und die die These dieses Projekts bestätigen:
            <br/><br/>Der Mensch bleibt ein soziales Wesen.
            <br/><br/><i>Kerstin Wördehoff und Liliane Zuuring, Autorinnen, 2021</i>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          </Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "IMG02",
    height: sectionDefaultHeight,
    image: image02,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>„Wir bleiben in Verantwortung: Diesen Satz haben mein Team und ich so sehr verinnerlicht. Und das ist mein Lichtblick, denn ich kann etwas ausrichten. In welcher Phase spüren wir eine größere Selbstwirksamkeit als jetzt?“</Text>
        <Text bottompadding underlined>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/02_JanineWestphal_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding>Janine Westphal, Leitungsteam KiTa-Vogelnest - Essen-Altenessen, 5. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG03",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image03,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Egal wie sehr wir vor Corona daran gearbeitet haben, eine gute Work-Life-Balance hinzubekommen und wie sehr wir uns geschworen haben, dass wir aufeinander aufpassen und auch abschalten können: Nun habe ich den Eindruck, dass die Kita mit uns körperlich verschweißt ist.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/03_BaerbelWagner_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Bärbel Wagner, Leitung KiTa Vogelnest - Essen-Altenessen, 5. Mai 20220</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG04",
    height: sectionDefaultHeight,
    image: image04,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Ruhrbischof Franz-Josef Overbeck feiert in der Kirche der Bistumsakademie "Die Wolfsburg" seine erste "digitale" Messe am Ostersonntag. Per Livestream auf Facebook erreicht er so die Menschen. Aufgrund der Allgemeinverfügung vom 17. März 2020 für das Essener Stadtgebiet ergeht, dass alle öffentlichen und privaten Veranstaltungen ab 15 Personen untersagt sind. Dies gilt auch für Gottesdienste und Veranstaltungen der Religionsgemeinschaften.</Text>
        <Text bottompadding>Mülheim a.d. Ruhr, 12. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG05",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image05,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Distanz passt nicht zum Christsein. Wenngleich Abstand zum anderen in diesen Tagen Nächstenliebe offenbart.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/05_BischofOverbeck_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Bischof Franz-Josef Overbeck</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG06",
    height: sectionDefaultHeight,
    image: image06,
    imagePosition: "top",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Carla sieht ihren neugeborenen Bruder Tijan das erste Mal seit seiner Geburt vor fünf Tagen. André Paschy, ihr Vater, holt seine Frau Judy und deren neugeborenen Sohn Tijan Fritz Sanga nach fünf Tagen aus dem Uniklinikum Essen ab. Er hat seine Frau und seinen Sohn seit der Kaiserschnittgeburt nicht mehr gesehen, weil die Corona-Auflagen des Klinikums dies nicht
          zugelassen haben. Es ist auch die erste Begegnung für die restliche Familie mit dem Neugeborenen Tijan. Die Familienzusammenführung findet zur Vorsicht auf dem Parkplatz vor dem Mietshaus statt.</Text>
        <Text bottompadding>Essen, 30. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG07",
    height: sectionDefaultHeight,
    image: image07,
    imagePosition: "top",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>„Die Geburt in der Corona-Zeit ist eine Geschichte des Wartens.“</Text>
        <Text bottompadding underlined>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/07_FamiliePaschy_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding>Familie Paschy</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "PDF07A",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text tall black bottompadding>
            Tijan schreit. So weit, so normal. Doch die Welt, in die Tijan gerade erst per Kaiserschnitt hineingeboren wurde, ist nicht mehr normal. Man schreibt den 26. April 2020. Eine globale Pandemie wütet. Die Hebamme nimmt ihn und bringt ihn zu seinem Vater André Paschy. Ein Arzt näht gerade Judys Operationswunde zu. André nimmt zum ersten Mal seinen Sohn in den Arm. Tijan wird ganz ruhig ... dann niest er herzhaft. Der Arzt hält in seinem Tun inne, dreht sich um und schaut. Alle im Kreißsaal sehen verdutzt zu Vater und Sohn hinüber. Bis der Arzt die Spannung löst. „Corona“, bemerkt er kurz und knapp. Und alle lachen. „Das war der schönste Moment von allen“, schwärmt André.
          </Text>
          <Text xl black>»Das war der schönste Moment von allen«</Text>
          <Text tall black bottompadding>
            Die Geschichte rund um Tijans erstem Tag in dieser Corona-Welt bis zu dem Moment auf den Fotos, in dem seine Familie sich auf dem Parkplatz vor dem Mietshaus, in dem die Paschys wohnen, zum „Babygucken“ zusammenfindet, lässt sich nur aus mehreren Perspektiven erzählen. Eine ist die seiner Mutter Judy Mkambe Sanga-Paschy. Sie wird ungewöhnlich durch den Faktor Sprache, denn sie spricht zur Zeit der Geburt nur schlecht Deutsch. Was die Sache mit der Corona- Isolation knifflig macht. André muss draußen bleiben. Darf nur zum Zeitpunkt der Geburt hinein. Aber kaum jemand spricht auf der Station Englisch.
            <br/><br/>„Ich wollte einfach da raus“, gesteht sie. Judy fühlt sich alleine. Missverstanden. „Es fühlte sich für mich nicht fair an.“ Sie versucht heute, die Erinnerungen einfach wegzulachen. „Ich war sehr ängstlich. Ich sehe meine Mutter nicht, ich sehe meinen Mann nicht“, erzählt sie. Und lacht und lacht. „Ich konnte nur weinen.“ Die Gefühle fahren immer noch Achterbahn.
            <br/><br/>Die Perspektive von André ist eine des nicht enden wollenden Wartens. Stunden über Stunden, 18 insgesamt. „Das war nicht schön“, gesteht er. Schläft im Auto. Harrt aus. Bis man ihn zur Geburt hineinlässt. Der Mensch bleibt ein soziales Wesen ... „kann man das in solch einer Situation so sagen?“, fragt André. Die Pflegekräfte, die Ärzte, die er trifft, alle sind „supernett“! Doch die zur Corona-Zeit herrschenden Vorgaben. „Nein, da muss ich sagen: Sozial war das alles nicht.“
          </Text>
          <Text xl black>»Sozial war das alles nicht«</Text>
          <Text tall black>
            Eine Perspektive ist die von Schwester Carla. „Und, ist das Baby schon da? Ist es schon da?“, plappert sie dauernd vor sich hin am Tag von Tijans Geburt. Dann muss sie noch fünf lange Tage warten, bis sie ihr Brüderchen vor sich hat. Als auch sie ihn dann im Arm hält, „wollte ich ihn gar nicht mehr loslassen.“
            <br/><br/>Der Parkplatz: Um Tijan in der Familie willkommen zu heißen, werden die Paschys kreativ. „Wir mussten uns überlegen, wo man den Kleinen einem Teil der Familie zeigen kann“, erzählt André. „Im geschlossenen Raum?“ Bei Corona? „Schwer!“, betont er. Es kommen Andrés Mutter, die Schwester – und auch der Neffe und die Nichte.
            <br/><br/>Tijan Fritz Sanga. Was bleibt, ist eine Frage an die Eltern. Würdet Ihr Euch wünschen, dass er später geboren worden wäre? Nicht in Pandemiezeiten eben. „Im Endeffekt ... wir sind ja froh, dass wir ihn haben. Es ist oookaaay“, sagt er gedehnt.
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          </Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "IMG08",
    height: sectionDefaultHeight,
    image: image08,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall>Separates Mittagessen für die streng getrennten Bewohner eines Stockwerkes des AWO-Essen Altenpflegeheims Kurt-Schumacher-Zentrum. Vor Corona haben an jedem der Tische jeweils vier Bewohner gegessen. Seit dem 14.03.2020 gilt ein Besuchsverbot in Alten und Pflegeheimen in NRW. Bei einem Erstverstoß kann ein Bußgeld in Höhe von 200 Euro verhängt werden.</Text>
        <Text bottompadding underlined>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/09_Janas_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding>Beate Janas, Pflegebereichsleitung - Essen, 23. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG09",
    height: sectionDefaultHeight,
    imagePosition: "right",
    image: image09,
    content:
      <OuterTextContainer left><TextContainer white>
        <Text tall black>„Ich bin alt. Schlimm ist das doch für junge Menschen.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/08_Hinz_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Walburga Hinz, Altenheim Bewohnerin - Essen, 23. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG10",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image10,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Corona ändert nichts an dem Spaß, den wir miteinander haben.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/10_Wittig_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Yvonne Wittig, Alltagsbegleiterin - Essen, 23. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG11",
    height: sectionDefaultHeight,
    image: image11,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Nach fünf Wochen darf Jürgen Krause, Inhaber eines Plattenladens, wieder sein Geschäft öffnen. Er hat den Laden im Mai 1976 eröffnet und lebt von seinen Stammkunden, die es heute am ersten Wiedereröffnungstag mit großer Freude zurück in "ihren" Laden zieht. Peter B. ist mit dem Fahrrad aus Wattenscheid gekommen. Er muss sich noch eine Maske kaufen, um den Laden betreten zu dürfen.</Text>
        <Text bottompadding>Essen-Steele, 20. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG12",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image12,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Corona offenbart Zusammenhalt. Das ist der Solidaritätspakt meiner Kunden mit mir.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/12_JuergenKrause_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Jürgen Krause</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG13",
    height: sectionDefaultHeight,
    imagePosition: "right",
    image: image13,
    content:
      <OuterTextContainer left><TextContainer white>
        <Text tall underlined black>Laura Zeiger ist Sopranistin und Stipendiatin beim Projekt „Live Music Now“ von Yehudi Menuhin. Im Rahmen des Projekts gibt sie ein freies Corona-Konzert für die Bewohner des AWO-Essen Altenpflegeheims Kurt-Schumacher-Zentrum. Seit dem 14. März 2020 gilt ein Besuchsverbot in Alten- und Pflegeheimen in NRW.</Text>
        <Text bottompadding black>Essen-Überruhr, 21. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG14",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image14,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Musik bedeutet gerade jetzt extrem viel.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/14_LauraZeiger_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Laura Zeiger, Sopranistin</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "PDF14A",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text tall black bottompadding>
            Wie klingt Corona? Ist es laut? Ist es leise? Harmonisch? Disharmonisch? Laura Zeiger kennt sich mit dem Klang von Dingen aus. Die 26-Jährige ist Sopranistin und studiert Gesang an der Folkwang Universität der Künste. Die Welt der Töne ist nicht nur ihre Leidenschaft, es ist ihr Beruf. Wenn die Musikerin Laura Zeiger an Corona denkt, dann kommt ihr ein Begriff in den Sinn: Stille. „ Corona ist unangenehm still. Stille kann sehr schön sein, Stille kann sehr einkehrend sein“, erklärt sie. „Die Corona-Stille jedoch ist sehr bedrückend, sehr schwer. Alles ist lahmgelegt, es gibt keine Freude, keinen Tanz, keinen Jubel – gar nichts.“
          </Text>
          <Text xl black>»Corona ist unangenehm still«</Text>
          <Text tall black bottompadding>
            Musik in einer Extremphase. Musik in Zeiten von Corona. Musik hören. Musik machen. Musik erleben. Was bedeutet dies alles? Dieser eine Tag im April 2020, an dem Laura Zeiger im Rahmen des Projekts „Live Music Now“ vor den Bewohnern des AWO-Altenpflegeheims Kurt- Schuhmacher-Zentrum ein Konzert gibt, lässt sich als ein Beispiel dessen sehen, was Musik in Zeiten der Pandemie bewirken kann. Auch an diesem Tag begleitet die 26-Jährige das Thema Stille, wenn auch in einem anderen Kontext. „Berge mich in Deinem Arm, halt mich in Deiner starken Hand“, rezitiert Laura Zeiger einen Auszug aus dem Lied „Still“ von Hillsong, das sie als ganz persönliches Lied den Bewohnern mit auf den Weg gibt. „Weil ich Hoffnung spenden wollte“, erklärt sie. „Ich versuche die Menschen mit der Musik zu halten, zu berühren und sie auf die Reise mitzunehmen “, erklärt sie und rezitiert aus dem Song: „Auch wenn Stürme wehen, werde ich schützend über Dir stehen ... Das war mir wichtig, das wollte ich persönlich teilen.“
            <br/><br/>Die Sopranistin empfindet auch heute noch Rührung, wenn sie an das Konzert denkt. „Die Bewohner standen alle auf ihren Balkonen mit Abstand und haben es so genossen, einfach wieder ein bisschen Kontakt zur Außenwelt haben zu können und zu spüren: ‚Aha, es wird sich um uns gekümmert. Da ist wieder schöne Musik und wir können uns daran erfreuen‘.“ Darum findet sie, es muss in der Pandemie noch viel mehr solcher Aktionen geben.
          </Text>
          <Text xl black>»Musik bedeutet gerade jetzt extrem viel«</Text>
          <Text tall black>
            „Musik heilt, Musik tröstet, Musik bringt Freude“ lautet ein Zitat von Yehudi Menuhin, das sich auf der Homepage von „Live Music Now“ findet. Ein Balsam für die Seele in einer Extremphase wie dieser? „Ja,“ ist sich Laura Zeiger sicher, Musik bedeute gerade jetzt „extrem viel. Man hat gemerkt, dass Musik verbindet, sie zeigt ‚Hey, du bist nicht alleine!“
            <br/><br/>„Musik ist das, was man nicht erklären kann! Musik ist wie die Liebe, die kann man auch nicht erklären, sie ist einfach da. Genauso wie Gefühle und der Zwischenmenschliche Kontakt. Durch Musik wird so viel freigesetzt: Ich kann weinen, ich kann lachen, ich kann schreien – alles das kann nur durch Musik ausgelöst werden.“ Das gilt es zu bewahren. Der Mensch braucht Gefühle, braucht Kontakt, braucht Nähe. „Der Mensch ist kein Einzelgänger. Wir sind alle füreinander verantwortlich. Wir sind alle eins.“
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          </Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "IMG15",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image15,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>Die Freunde Leo und Manuel können nur noch Joggen als Sport betreiben. Nach dem Sport baden die beiden in der Ruhr. Die Schwimmbäder haben seit dem 17. März 2020 wegen Corona geschlossen.</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/15_LeoTeichmann_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Leo Teichmann, Student - Essen, 24. März 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG16",
    height: sectionDefaultHeight,
    image: image16,
    imagePosition: "top",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined>Ein Team der AWO-Essen "Soziale Dienste" teilt seit Beginn der Schul- und Kita-Schließungen (16. März 2020) täglich 90 warme Mahlzeiten an bedürftige Familien im Essener Norden aus. Die Helfer haben so einen täglichen Kontakt zu den Familien und können bereits frühzeitig bei Problemen, entstanden durch die soziale Distanzierung, unterstützen und helfen.</Text>
        <Text bottompadding>Essen, 21. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG17",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image17,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Im Lockdown umdenken. Soziale Arbeit ist in vielen Bereichen möglich.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/17_Platzek_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Maicl Platzek, Diplom-Pädagoge</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG18",
    height: sectionDefaultHeight,
    image: image18,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined>Schulpause mit räumlicher Distanzierung: Der Mathe-Leistungskurs des Abiturjahrgangs 2020 vom Gymnasium Essen-Überruhr darf das erste Mal seit dem 13. März 2020 wieder am Unterricht teilnehmen. Die Kurslehrerin wiederholt mit ihren freiwillig anwesenden Schülern den Stoff für die kommenden Abiturprüfungen. In der ersten Woche nach den Osterferien musste das Lehrerkollegium das Schulgebäude mit einem Hygiene- und Abstandskonzept auf die Wiederaufnahme des Präsenzunterrichts vorbereiten.</Text>
        <Text bottompadding>Essen, 28. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG19",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image19,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Abi-Zeugnisvergabe mit Hygienekonzept: Aber die Gemeinschaft bleibt trotzdem spürbar.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/19_AnnelieHegemann_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Annelie Hegemann, Mathelehrerin</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG20",
    height: sectionDefaultHeight,
    image: image20,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined>Seit dem 04.05.2020 dürfen Friseure in NRW nach fast sieben Wochen wieder öffnen. Im Salon von Oskar und Natascha wird seit dem an sechs Tagen in der Woche gearbeitet und versucht, den Kunden trotz der strengen Hygieneauflagen einen angenehmen Aufenthalt zu ermöglichen.</Text>
        <Text bottompadding>Essen, 13. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG21",
    height: sectionDefaultHeight,
    imagePosition: "right",
    image: image21,
    content:
      <OuterTextContainer left><TextContainer white>
        <Text tall black>„Komm klar und richte dein Krönchen!“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/21_Natascha_L_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Natascha L. - Friseurin</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG22",
    height: sectionDefaultHeight,
    image: image22,
    imagePosition: "top",
    content: null
  },
  {
    sectionId: "PDF22A",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text tall bottompadding black>
            Stell dir vor, auf Deiner Brust liegen Glasscherben, die sich in dein Fleisch bohren. Nun stell dir vor, jemand legt seine Hände auf deine Brust. Und streicht mit diesen ganz fest über deinen Oberkörper. Minuten, Stunden, sogar Tage. Und du bist so schwach, kannst kaum deine Hand heben. Du kannst nichts tun, du musst es ertragen. Aber wie?
            <br/><br/>So fühlt sich für Natascha L. Covid-19 an. Vielmehr die durch den Virus verursachte Lungenembolie. Natascha ist heute 35. Ehefrau. Mutter einer kleinen Tochter. Inhaberin eines Friseursalons in Essen. Und Covid-19-Überlebende.
            <br/><br/>Natascha erzählt ihre Geschichte als Mahnung für alle Menschen, Covid-19 nicht zu unterschätzen. „Es war die schlimmste Zeit meines Lebens“, gibt sie zu. Es ist eine Geschichte der Menschlichkeit, des Zusammenhalts. Und doch gibt es Momente der Verzweiflung.
          </Text>
          <Text xl black>»Komm klar und richte dein Krönchen«</Text>
          <Text tall black bottompadding>
            Sie sitzt im Auto an der roten Ampel und weint. „Weil ich nicht mehr weiter wusste“, gesteht sie. Es sind die existenziellen Sorgen, die sie umtreiben. Gerade was den Salon betrifft. Doch den Kopf hängen lassen? Nie! Darum zeigt ein aus Klebeband geformter Smiley und kein X auf dem Boden vor der Kasse, welchen Abstand die Kunden halten müssen. Das zaubert ein Lächeln auf ihre Gesichter.
            <br/><br/>Sie selbst sitzt im Auto und weint. „Komm klar, richte dein Krönchen und fahr weiter“, sagt sie sich. Keine Mitarbeiterin, kein Kunde: Niemand trägt das Virus in den Salon. Natascha gönnt sich eine Auszeit – und fährt am 26. Oktober 2020 einen Tag ins Spa nach Düsseldorf. Wohl in der Sauna infiziert sie sich.
            <br/><br/>„Ich war zwei Monate komplett raus“, schildert sie. Da liegt sie nun also. Und hat nicht genügend Kraft, sich die Haare zu trocknen. „Das war eine ganz üble Zeit“, gesteht sie. Die Ärzte diagnostizieren eine Lungenembolie, die durch einen glücklichen Zufall schon wieder abebbt. Als sie ausfällt, übernehmen alles „die Mädels“, damit der Salon nicht in die Pleite rutscht.
          </Text>
          <Text xl black>»Meine Mama, meine Mama ist wieder da«</Text>
          <Text tall black>
            Da ist diese Sache mit ihrer kleinen Tochter und dem Kaufladen. „Wie oft dieses Kind an meinem Bett saß und geweint hat“, beschreibt Mutter Natascha. „Mama, komm mit mir Verkaufen spielen“, sagt die Vierjährige. „Aber ich konnte nicht aufstehen.“ Einmal schafft sie es doch bis ins Kinderzimmer. Legt sich in das Bett der Tochter und sieht dabei zu, wie das Mädchen mit dem Papa Verkaufen spielt. Als Natascha aus dem Krankenhaus entlassen wird und zum ersten Mal das Kind wieder selbst vom Kindergarten abholen kann, kommt das Mädchen weinend zum Tor gerannt und ruft über das ganze Gelände: „Meine Mama, meine Mama ist wieder da.“
            <br/><br/>„Für jeden ist das so weit weg, auch für mich war es das.“ Doch die Menschen müssen die Gefahr sehen. Sie müssen verstehen. „Wir streben alle nach Glück, nach Freiheit, nach Vitamin D, nach Sonne. Und sie fügt eindringlich hinzu: „Ich will mein Baby nicht abgeben und mein Baby ist der Salon, das sind meine Mitarbeiter. Auch wenn nochmal ein Lockdown kommt, und wenn ich mir selber keine Anziehsachen mehr kaufen kann, mir egal.“
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          </Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "IMG23",
    height: sectionDefaultHeight,
    image: image23,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall>„Für alles gibt es nur noch den einen Ort: die Wohnung.“</Text>
        <Text bottompadding underlined>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/23_RamaonaLettkamp_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding>Ramona Lettkamp, alleinerziehende Mutter</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG24",
    height: sectionDefaultHeight,
    image: image24,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined>Clemens und Oskar toben in ihrem Zimmer. Ramona ist alleinerziehende Mutter. Sie wohnt in einer kleinen Dachgeschosswohnung ohne Balkon. Die Söhne Oskar und Clemens wohnen wochenweise bei ihr oder deren Vater.</Text>
        <Text bottompadding>Essen, 11. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG25",
    height: sectionDefaultHeight,
    image: image25,
    imagePosition: "top",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Der Musikstudent Manuel übt mit seinem Trompetenlehrer "Ferrie" im Wald bei Essen-Werden. Weil die beiden noch immer nicht in den Innenräumen der Hochschule mit Blasinstrumenten spielen dürfen, entscheiden sie sich für einen alternativen Übungsort.</Text>
        <Text bottompadding>Essen, 29. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG26",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image26,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Man muss schon aufpassen, dass man nicht den Willen verliert, dran zu bleiben.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/Website_Trompeter_Manuel_Galemann.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Manuel Galemann, Musikstudent</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG27",
    height: sectionDefaultHeight,
    image: image27,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Die achtjährige Tochter Judy isst ihren Nachtisch alleine im Wohnzimmer. Familie Sabbagh kann während des Lockdowns nur im engsten Familienkreis das abendliche Fasten brechen. Im Fastenmonat Ramadan würde die syrische Familie normalerweise gemeinsam mit anderen Freunden und Familien essen und beten. Die Familie lebt seit dem 26. November 2015 in Essen. Sie stammt ursprünglich aus Aleppo und hat ihre Heimat wegen des dortigen Bürgerkrieges im Jahr 2015 verlassen. 13 Tage dauerte ihre Reise über die Türkei nach Deutschland.</Text>
        <Text bottompadding>Essen, 6. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG28",
    height: sectionDefaultHeight,
    imagePosition: "right",
    image: image28,
    content:
      <OuterTextContainer left><TextContainer white>
        <Text tall black>„Corona ist ein schlechter Traum.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/28_Obayda_Syrische_Familie_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Obayda Sabbagh mit Tochter und Sohn</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG29",
    height: sectionDefaultHeight,
    image: image29,
    imagePosition: "top",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined>Seit mittlerweile vier Wochen sind Kitas und Schulen wegen der Corona-Pandemie geschlossen. Michael Walta ist Bauchredner und Zauberer aus Essen. Über das Stadtradio hat er angeboten, gegen eine kleine freiwillige Spende für einen lokalen Zirkus vor den Wohnungen und Häusern eine Zaubershow zu veranstalten. So möchte er den Kindern und auch ihren Eltern ein wenig Abwechslung bieten. Hier ist er bei Familie L. in Essen-Freisenbruch.</Text>
        <Text bottompadding>Essen, 14. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG30",
    height: sectionDefaultHeight,
    imagePosition: "right",
    image: image30,
    content:
      <OuterTextContainer left><TextContainer white>
        <Text tall black>„Beim Zaubern sind alle gleich.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/Website_Bauchredner_Michael_Walta.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Michael Walta, Bauchredner und Zauberer</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "PDF30A",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text tall bottompadding black>
            Michael Walta schlüpft in sein Clownskostüm, malt sein Gesicht an. Sorgfältig packt er ein, was er zum Zaubern braucht, steigt in das Auto seiner Frau. Sie startet den Motor und er damit seine Mission: Freude zu den Kindern bringen, die in der Corona-Zeit „derzeit nicht viel erleben“. Das ist ihm, der es liebt, ein Lächeln in Kindergesichter zu zaubern, ein Dorn im Auge.
            <br/><br/>Wie es begann: Er überlegt mit einem Freund, was in der Corona-Zeit möglich ist. Der bringt ihn auf diese zauberhafte Idee: an Fenstern zaubernd Kinder zu verzaubern. Plakate und Flyer entwirft er für das Projekt, um bekannt zu machen, dass sich Interessierte melden können.
            <br/><br/>Und sie melden sich. Und melden sich. Und melden sich: 80 Shows in vier Tagen spielt er. Das bedeutet: 80 Mal 15 Minuten Abwechslung in den Corona-Alltag bringen. Er reist quer durch die Stadt in einer Phase, als alle daheim sitzen müssen im Lockdown. „Das war schon ziemlich heftig.“
          </Text>
          <Text xl black>»Freude zu den Kindern bringen, die derzeit nicht viel erleben«</Text>
          <Text tall black bottompadding>
            Der Wagen fährt vor, der Blick schweift die Hausnummern entlang. Der Wagen hält an. Der Clown steigt aus. Noch wenige Schritte und er ist auf seiner Bühne aus Asphalt, Steinen oder auch schon mal Rasen. Von dort blickt Walta nicht wie gewohnt in einen großen Raum mit vielen Stühlen, sondern hoch zu seinem Publikum. Blickt in Gesichter in Fenstern. Und fängt an zu zaubern. Bricht die Corona-Langeweile.
            <br/><br/>Gut acht Stunden am Tag ist er so unterwegs. „Oft habe ich von 10 bis 16 Uhr gespielt.“ Der Wortgewandte spürt: Zaubern bedarf nicht vieler Worte. Es spricht für sich. „Es waren auch Migrantenkinder dabei, die nicht viel Deutsch sprechen.“ Und trotzdem lachten und verstanden. „Manchmal kamen noch Verwandte und Nachbarn dazu, setzten sich in den Garten oder schauten aus den Nachbargärten zu. Dann haben mir die Menschen an Seilen und in Eimern oder an Angeln Süßigkeiten, was zu trinken und Spenden runtergereicht.“
            <br/><br/>Die Spenden gehen an einen Zirkus. Der lachende, fröhliche Clown behält sie nicht für sich. Obwohl ihm eigentlich gar nicht so zum Lachen sein dürfte. Denn seinen Job kann der Bauchredner und Künstler nicht ausüben. Es kommt kein Geld rein. Das Ladenlokal in Kupferdreh will bezahlt sein. Der Traum von der ersten eigenen Solo-Tour – geplatzt. Ein Jahr hatte er diese Premiere geplant. „Ich hab am 6. März 2020 in Waltrop gespielt, einen Tag später noch in Dortmund, dann war Schluss mit lustig. Dann war Feierabend. Dann kam Corona.“
          </Text>
          <Text xl black>»Beim Zaubern sind alle gleich«</Text>
          <Text tall black>
            Sein Lohn jetzt: Das Funkeln in den Augen der Kinder zu sehen, ihre Reaktionen. „Nach dem zweiten Trick haben die Kinder gelacht. Die Energie, die man dadurch zurückbekommt, kann man mit Geld nicht bezahlen.“ Beim Zaubern seien alle gleich. „Da fällt es nicht auf, wer sozial schwach ist. Alle reagieren gleich. Lachen kann man nicht kaufen. Das kommt von Herzen – oder eben nicht.“
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          </Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "IMG31",
    height: sectionDefaultHeight,
    image: image31,
    imagePosition: "top",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Der Covid-19-Patient Kenan Demir wurde vor zwei Tagen auf die Isolierstation des Uniklinikums Essen eingeliefert. Er hat Schwierigkeiten zu atmen und wird von Jessica Soyk, Fachkrankenschwester für Intensivmedizin, versorgt. Zu diesem Zeitpunkt sind nur noch zwei Covid-19-Patienten auf der Isolierstation des Uni Klinikums. </Text>
        <Text bottompadding>Essen, 3. Juni 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG32",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image32,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Muss erst eine Pandemie kommen, damit Menschen verstehen, was Pflegekräfte leisten?“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/32_JessicaSoyk_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Jessica Soyk, Fachkrankenschwester für Intensivmedizin</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG33",
    height: sectionDefaultHeight,
    image: image33,
    imagePosition: "top",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Die Welt von Kenan Demir im Jahr 2020 ist eine des Surrealen. Im Frühjahr erkrankt der heute 61-jährige Türke an Corona. Steht auf der Schwelle zwischen Leben und Tod. Verbringt Monate in Krankenhäusern. Wenn er heute daran zurückdenkt, dann begleitet ihn vor allem ein Nebel aus Unwissen.</Text>
        <Text bottompadding>Essen, 3. Juni 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG34",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image34,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Am Ende sind es über 100 Tage, die ich in den Kliniken verbringe.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/34_KenanDemir_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Kenan Demir, Corona infizierter Intensivpatient</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG35",
    height: sectionDefaultHeight,
    image: image35,
    imagePosition: "top",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined>Anna und Marcel heiraten am 8. Mai 2020 in Bochum. An der standesamtlichen Trauung dürfen im Rathaus aufgrund von Corona bedingten Auflagen nur zehn Personen teilnehmen. Einschränkungen bestimmen auch die private Hochzeitsfeier im Garten der Brauteltern. Sie durfte nur mit physischer Distanz stattfinden.</Text>
        <Text bottompadding>Bochum, 8. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG36",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image36,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Die Belohnung: Eine Hochzeit, die wir nie vergessen werden.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/36_Schalla_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Anna und Marcel Schalla, Hochzeitspaar</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG37",
    height: sectionDefaultHeight,
    image: image37,
    imagePosition: "top",
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Portrait von Klaus und seinem Freund Hugo. Beide befinden sich für zwei Wochen in häuslicher Quarantäne. Nach dem radikalen südafrikanischen Lockdown sind beide mit dem vorletzten Flugzeug, das deutsche Bürger zurück ins Land bringt, am 22. April 2020 in Frankfurt am Main gelandet. Klaus hat seit 20 Jahren ein Haus in Kapstadt und verbringt seine deutschen Winter mit Hugo in Südafrika.</Text>
        <Text bottompadding>Essen, 29. April 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG38",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image38,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Wir hingen fest in Kapstadt.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/38_KlausSiebald_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Klaus Siebald</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "PDF38A",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text tall bottompadding black>
            „Wir hatten das Gefühl, sie bringen uns in ein Lager“, erinnern sich Klaus Siebald (81) und Hugo Thielmann (66) an ihre bedrückende, irreal wirkende Rückreise in Corona-Zeiten von ihrer Winterwahlheimat Kapstadt in Südafrika nach Deutschland.
            <br/><br/>Das Gefühl, in ein Lager gebracht zu werden, beschleicht die beiden, als sie am Stadion in Kapstadt darauf warten, den Flughafen zu erreichen. Sie stehen in der Schlange. Mit Abstand. Sie werden einen Schritt „vorwärts getrieben“. Der Ton ist „barsch“. Wieder warten. Ein Schritt. Jeder hat sein Gepäck dabei. Warten. Registrierung. Warten. Ein Schritt. Gesundheitscheck. Keine Information, nur hier und da ein aufgeschnappter Gesprächsfetzen. Aber die Mitarbeiter in der Botschaft sind ausgesprochen nett und hilfsbereit. Dennoch: „Beängstigend“ wirkt die Situation.
          </Text>
          <Text xl black>»Wir hingen fest in Kapstadt«</Text>
          <Text tall black bottompadding>
            Am 3. April 2020 hätte es geplant zurück nach Deutschland gehen sollen. Doch der Flug wurde storniert. Sie buchten bei einer anderen Gesellschaft – und kaum gebucht, kam auch da die Stornierung. „Wir hingen fest.“
            <br/><br/>Sie nehmen Kontakt zur deutschen Botschaft auf. Warten. Dann die Nachricht: Es gibt einen Flug. „Wir sollten um 16 Uhr am Stadion sein. Dafür hatten wir eine Sondergenehmigung, dass uns jemand von der Wohnung dorthin bringen darf.“ Nach dem Erlebnis am Stadion geht‘s in eiskalten Bussen zum Flughafen. Alles mit Abstand. Aber dann sitzen sie mit „360 Leuten eng im Flugzeug, mit Handschuhen und Masken“. Kalt ist es. „Ich bin da drin fast erfroren.“ Die Stewardessen sehen aus, „als würden sie zum Mond fliegen“, so sind sie „eingepackt“. „Wenn das Fliegen immer so wäre, ich würde nie mehr fliegen.“
          </Text>
          <Text xl black>»Mit 360 Leuten eng im Flugzeug, alle mit Handschuhen und Masken«</Text>
          <Text tall black bottompadding>
            In Frankfurt dürfen immer nur 40 Passagiere aussteigen. Warten. Auch am Gepäckband. Zweieinhalb Stunden allein dort. Dann geht es endlich Richtung Essen. Getestet werden sie nicht, gehen aber zehn Tage in Quarantäne.
            <br/><br/>Solidarität haben sie erfahren in dieser Zeit: Nachbarn und Freunde kaufen ein für die beiden. Und auch wenn der Flug eine „der schlimmsten Erinnerung in dieser Corona-Zeit ist“, ist es für die beiden keine Frage, im Oktober 2020 wieder zurückzufliegen in ihr Winterdomizil Südafrika mit einem der ersten Flüge, die überhaupt wieder dorthin starten. „Wir haben Eigentum in Südafrika, darum muss man sich kümmern.“
          </Text>
          <Text xl black>»Weihnachten erwischt sie das Virus«</Text>
          <Text tall black>
            Ausgerechnet in Kapstadt erwischt das Virus sie dann. Ausgerechnet Weihnachten 2020.
            <br/><br/>Heiligabend noch besuchen sie Freunde, dann am ersten Weihnachtstag geht es Siebald nicht gut. „Ich fühlte mich wie bei einer Grippe, so elend war einem. Als das nach einigen Tagen nicht besser wurde, bin ich in die Klinik gefahren.“ Dort stellen die Mediziner die Corona-Infektion fest. „Wir hatten kein Fieber, haben nicht den Geruchs- und Geschmackssinn verloren, hatten keine typischen Corona-Symptome. Wir waren einfach nur platt, hatten keinen Appetit, keinen Antrieb. Man hat kein Interesse mehr an irgendwas gehabt. Wir haben in der abgedunkelten Wohnung gelegen. Wo wir das herbekommen haben – keine Ahnung.“
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          </Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },
  {
    sectionId: "IMG39",
    height: sectionDefaultHeight,
    image: image39,
    content:
      <OuterTextContainer left><TextContainer>
        <Text tall underlined>Seit heute (11. Mai 2020 ) darf die Gastronomie wieder öffnen. Der Gastwirt Thorsten Teriete von der Essener Kult-Kneipe Panoptikum versucht, trotz der strengen Auflagen wieder Gäste bestens bedienen zu können. </Text>
        <Text bottompadding>Essen, 11. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG40",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image40,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Die Hälfte der Zeit lief normal! Das hat uns den Arsch gerettet.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/40_ThorstenTeriete_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Thorsten Teriete, Kneipenwirt</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG41",
    height: sectionDefaultHeight,
    image: image41,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined>Ramona schmust mit ihrem Sohn Lennard. Sie ist alleinerziehende Mutter und lebt mit ihrem fünfjährigen Sohn in einer kleinen Wohnung. Während des Lockdowns kümmert sie sich auch um ihre Nachbarn. Ramona ist die selbst ernannte "Mutter der Kolonie" und hat immer ein offenes Ohr für deren Sorgen und Nöte.</Text>
        <Text bottompadding>Essen, 6. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG42",
    height: sectionDefaultHeight,
    imagePosition: "left",
    image: image42,
    content:
      <OuterTextContainer right><TextContainer white>
        <Text tall black>„Das Glas ist halb voll, nicht halb leer!“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/42_RamonaKamminski_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Ramona Kamminski, alleinerziehende Mutter</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG43",
    height: sectionDefaultHeight,
    image: image43,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>„Das Dilemma mit den wechselnden Bestattungsregeln dem Trauernden rational vermitteln.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/43_RobinLelgemann_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding>Robin Lelgemann, Bestatter - Essen, 11. August 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG44",
    height: sectionDefaultHeight,
    image: image44,
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall>Trauerfeier für Natalia D. mit anschließender Beisetzung. Ihr Ehemann Alexander und Sohn Ilja trauern am Sarg, es ist unter den aktuellen Coronabedingungen nur im Freien möglich. Viele Verwandte der russischstämmige Familie konnten wegen den aktuellen Reisebeschränkungen nicht an der Bestattung teilnehmen. Alle anwesenden Trauernden müssen sich auf eine Liste eintragen, sollten eine Maske tragen und die Abstände untereinander einhalten.</Text>
        <Text bottompadding underlined>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/44_Ilja_D_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding>Ilja D. - Essen, 11. August 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG45",
    height: sectionDefaultHeight,
    image: image45,
    imagePosition: "top",
    content:
      <OuterTextContainer right><TextContainer>
        <Text tall underlined>ProAsyl Essen: Ein Besprechungsraum mit improvisiertem Corona-Schutz. Der Flüchtlingsberater Kaveh Shoaei spricht mit Klient Hamid aus dem Iran über seine Aufenthaltsgenehmigung. Hamid möchte in seinem alten Beruf als Werbetechniker arbeiten und eine Firma in Deutschland gründen. Vor Corona gab es bei ProAsyl eine offene Sprechstunde, aber nun sind nur noch Einzeltermine möglich.</Text>
        <Text bottompadding>Essen, 13. Mai 2020</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG46",
    height: sectionDefaultHeight,
    image: image46,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer white>
        <Text tall black>„Zu helfen hilft, sich lebendiger zu fühlen.“</Text>
        <Text bottompadding underlined black>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/46_Shoaei_webfinal.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text bottompadding black>Kaveh Shoaei, Flüchtlingsberater von ProAsyl Essen</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "PDF46A",
    content:
      <OuterTextContainer>
        <TextOnlyContainer>
          <Text tall bottompadding black>
            „In harten Zeiten sind wir bessere Menschen“, ist Kaveh Shoaei überzeugt. Er flüchtete vor 30 Jahren aus dem Iran, wo er mitten im Krieg zwischen dem Iran und dem Irak „einen großen Zusammenhalt“ erlebte.
            <br/><br/>Inzwischen ist er seit 23 Jahren Flüchtlingsberater bei Pro Asyl – und erlebt hier in der Corona- Krise wieder große Solidarität.
            <br/><br/>Da ist ein Schneider aus Holsterhausen, der unentgeltlich Masken näht, die die „Pro Asyl“- Mitarbeiter mit Informationen in verschiedenen Sprachen über Corona an Kunden verteilen können. Angesichts von teils sich an der Situation bereichernden Politikern fragt er sich da schon: „Wer ist da sozialer? Wer passt besser in unsere Gesellschaft?“
          </Text>
          <Text xl black>»Zu helfen hilft, sich lebendiger zu fühlen«</Text>
          <Text tall black bottompadding>
            Er berichtet von viel mehr Anfragen als sonst von Ehrenamtlichen, die gern dolmetschen würden. Darunter sind viele ehemalige Flüchtlinge. „Das zeigt doch, wie sozial der Mensch ist. Das Bedürfnis, mit anderen in Kontakt zu sein und zu helfen, ist groß. Das hilft auch, sich lebendiger zu fühlen.“
            <br/><br/>Lebendiger in einer Zeit, in der es viel weniger Sozialleben gibt.
            <br/><br/>Leer sind die Flure bei Pro Asyl. Kommt Kaveh Shoaei sonst zu seinem Arbeitsplatz, warten da schon Menschen auf Beratung. Jetzt in der Corona-Zeit schallt ihm keine fröhliche Begrüßung entgegen, kein babylonisches Sprachgewirr empfängt ihn, keine Frage mal eben im Vorübergehen dringt an sein Ohr. Da ist niemand, der noch mal eben ein fehlendes Dokument nachreicht. „Manchmal waren 50 Menschen draußen, ich musste mich regelrecht zum Büro durchkämpfen“, sagt der Flüchtlingsberater, der jetzt im Flur nur seine eigenen Schritte hört.
            <br/><br/>Selbst die Kollegen sieht er seltener. Weil nicht alle gleichzeitig vor Ort sein sollen, bestimmt das Homeoffice den Alltag. Mal eben zum anderen Schreibtisch eine Frage zur Situation in einem Land, das selbst nicht im Fokus der eigenen Arbeit steht, herüberrufen: Das geht nicht.
          </Text>
          <Text xl black>»Ein Corona-Phänomen: Viel mehr Frauen sind Opfer von Gewalt«</Text>
          <Text tall black bottompadding>
            Ein Phänomen im Corona-Jahr: Viel mehr Frauen, die Opfer von Gewalt sind, kommen zu dem engagierten Berater, erkundigen sich nach Bleibemöglichkeiten unabhängig vom gewalttätigen Partner. Erklären kann er sich das schon. Die Familien und Paare hocken in der Corona-Zeit aufeinander, teils ohne Arbeit, ohne die Möglichkeit, Sozialkontakte zu pflegen. Das ist problematisch.
            <br/><br/>Die Corona-Zeit ist für Flüchtlinge nicht leicht. Ein Grund sind geschlossene Botschaften. Für viele getrennte Familien bedeutet das: Keine Chance auf die lang ersehnte Familienzusammenführung. In den Herkunftsländern sterben Familienmitglieder. Kein Abschied ist möglich. Auch Angehörige von Kaveh Shoaei sterben im Iran an Corona.
            <br/><br/>Wie er persönlich diese Corona-Zeit aushält? „Meine Enkel sind meine Rettung.“
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          </Text>
        </TextOnlyContainer>
      </OuterTextContainer>
  },

];